import { AppState } from './shared/store/app.state';
import swal from "sweetalert";
import { CdnService } from "./services/cdn.service";
import { CertificateService } from "src/app/shared/services/certificate.service";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { ProfileService } from "./shared/services/profile.service";
import { NotificationHubService } from "./shared/services/notification-hub.service";
import {
  AfterViewInit,
  Component,
  HostBinding,
  OnInit,
  Renderer2,
} from "@angular/core";
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";

import { SettingsService } from "./core/settings/settings.service";
import { LoaderService } from "./shared/services/loader.service";

import { menuProfile } from "../app/routes/menu";
import { MenuService } from "./core/menu/menu.service";
import { GoogleAnalyticsService } from "ngx-google-analytics";
declare var gtag: Function;
import { AppConfigService } from "./services/app-config.service";
import { ChatService } from "./shared/services/chat.service";
// import { GAService } from "./shared/services/analytics.service";
import { filter } from "rxjs/operators";
import { AnalyticsService } from "./shared/services/analytics.service";
import { environment } from "src/environments/environment";
import { Store } from '@ngrx/store';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  @HostBinding("class.layout-fixed") get isFixed() {
    return this.settings.getLayoutSetting("isFixed");
  }
  @HostBinding("class.aside-collapsed") get isCollapsed() {
    return this.settings.getLayoutSetting("isCollapsed");
  }
  @HostBinding("class.layout-boxed") get isBoxed() {
    return this.settings.getLayoutSetting("isBoxed");
  }
  @HostBinding("class.layout-fs") get useFullLayout() {
    return this.settings.getLayoutSetting("useFullLayout");
  }
  @HostBinding("class.hidden-footer") get hiddenFooter() {
    return this.settings.getLayoutSetting("hiddenFooter");
  }
  @HostBinding("class.layout-h") get horizontal() {
    return this.settings.getLayoutSetting("horizontal");
  }
  @HostBinding("class.aside-float") get isFloat() {
    return this.settings.getLayoutSetting("isFloat");
  }
  @HostBinding("class.offsidebar-open") get offsidebarOpen() {
    return this.settings.getLayoutSetting("offsidebarOpen");
  }
  @HostBinding("class.aside-toggled") get asideToggled() {
    return this.settings.getLayoutSetting("asideToggled");
  }
  @HostBinding("class.aside-collapsed-text") get isCollapsedText() {
    return this.settings.getLayoutSetting("isCollapsedText");
  }
  locationPosition: any;


  isLandingPage: boolean = false;

  constructor(
    public settings: SettingsService,
    private oauthService: OAuthService,
    private profileService: ProfileService,
    private loaderService: LoaderService,
    private renderer: Renderer2,
    public menuService: MenuService,
    private notificationHub: NotificationHubService,
    private appConfiguration: AppConfigService,
    private chatService: ChatService,
    private router: Router,
    private certificateServices: CertificateService,
    private cdnService: CdnService,
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
    private store: Store<AppState>
  ) {
    this.saveRedirect();
    this.configureImplicitFlow();
    this.setUserRole();

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.isLandingPage = event['url'] == '/auth/landing';
      }
    });
  }
  ngAfterViewInit(): void {
    this.loaderService.httpProgress().subscribe((status: boolean) => {
      if (status) {
        this.renderer.addClass(document.getElementById("loader"), "loading");
      } else {
        this.renderer.removeClass(document.getElementById("loader"), "loading");
      }
    });
  }

  ngOnInit() {
    // if (this.oauthService.hasValidAccessToken()) {
    //   this.notificationHub.connect();
    //   // this.analytics.config();

       //this.chatService.loadNotifications();
      // this.profileService.getProfile().subscribe((profile) => {
      //   console.log(profile);
        //this.settings.setUserThemeSettings(profile.themeSettings);
        //this.settings.initUserThemeSettings();
      // });
    //   this.certificateServices
    //     .getThumbnails()
    //     .toPromise()
    //     .then((response) => {
    //       let s3public =
    //         "https://" +
    //         response.s3PublicRepository +
    //         ".s3.us-west-2.amazonaws.com/";
    //       this.cdnService.basePath = s3public;
    //     });
    // }
     this.sessionTimeout();
    // //prevent empty links to reload the page
    // document.addEventListener("click", (e) => {
    //   const target = e.target as HTMLElement;
    //   if (
    //     target.tagName === "A" &&
    //     ["", "#"].indexOf(target.getAttribute("href")) > -1
    //   )
    //     e.preventDefault();
    // });
  }

  private setEnvMenu(menu) {

    let env = environment.environment_name;
    let userRole = this.profileService.identity.userType;


    if(env == 'Production' || env == 'Stage' || env == 'Test'){

      //Remove Inbox
      if(userRole == 'Parent' || userRole == 'Student' || userRole == 'Administrator' || userRole == 'Counselor' || userRole == 'Teacher'){
        menu = menu.filter( item => item.text !== 'Inbox')
      }
    }


    return menu
  }

  private setUserRole() {
    if (!this.profileService.identity) return;
    let userRole = this.profileService.identity.userType;
    let usernameNo = this.profileService.identity.userIdRole;
    let menu = menuProfile[userRole];
    if (userRole == "Student") {
      menu[1].link = `${menu[1].link}/${usernameNo}`;
    }
    menu = this.setEnvMenu(menu);
    this.menuService.addMenu(menu);
  }
  private configureImplicitFlow() {
    var authCodeFlowConfig: AuthConfig = {
      // Url of the Identity Providerpush
      issuer:
        this.appConfiguration.configuration.environment.endPoints
          .authenticationServices,
      skipIssuerCheck: true,
      redirectUri: `${this.appConfiguration.configuration.environment.endPoints.baseUri}/auth/token-callback`,
      customTokenParameters: ["usernameNo"],
      postLogoutRedirectUri: `${this.appConfiguration.configuration.environment.endPoints.baseUri}`,
      clientId: "parent_portal_client",
      showDebugInformation: true,
      tokenEndpoint: `${this.appConfiguration.configuration.environment.endPoints.authenticationServices}/connect/token`,
      strictDiscoveryDocumentValidation: false,
      responseType: "code",
      scope: "openid parent offline_access IdentityServerApi",
      useSilentRefresh: true,
      dummyClientSecret: "",
      logoutUrl: "",
      customQueryParams: {
        role: "parents,teachers,students,administrators,counselors",
        lng: 'en',
        PostLogOutUri: `${this.appConfiguration.configuration.environment.endPoints.baseUri}`
      },
    };
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.setStorage(localStorage);
    this.oauthService.events.subscribe((e) => {
      if (e.type == "logout") {
        window.localStorage.clear();
      }
    });

    // this.oauthService.events
    //   .pipe(filter((e) => e.type === "session_terminated"))
    //   .subscribe(() => {
    //     console.debug("Your session has been terminated!");
    //   });
  }
  private sessionTimeout() {
    var existingTimeout = window.localStorage.getItem("sessionTimeout");

    const self = this;
    const timerId = window.setInterval(() => {
      let expirationTime = self.oauthService.getIdTokenExpiration();
      let leftTime = expirationTime - new Date().getTime();
      let modalRunning = false;
      if (!self.oauthService.hasValidAccessToken()) {
        self.oauthService.loadDiscoveryDocument().then((response) => {
          window.clearInterval(timerId);
          self.oauthService.logOut();
          self.router.navigate(["auth/logout"]);
        });
      }
      if (leftTime < 120000 && !modalRunning) {
        modalRunning = true;
        swal({
          title: `Session Timeout`,
          icon: "warning",
          text: "Are you still there? All users will be automatically logged out after a certain time of inactivity. To continue using the application please click Yes.",
          buttons: {
            cancel: true,
            confirm: {
              text: "Yes",
              value: true,
              visible: true,
              closeModal: true,
            },
          },
        }).then((res) => {
          if (res) {
            self.oauthService.refreshToken().then((token) => {
              console.log(token);
            });
          } else {
            self.oauthService.loadDiscoveryDocument().then((response) => {
              self.oauthService.logOut({
                reason:
                  "For security purposes all users get logged out automatically after a certain time.",
              });
            });
            modalRunning = false;
          }
        });
      }
    }, 60000);
  }
  private saveRedirect(){
    var pathname = window.location.pathname;
    var eventsUrl = 'events/id/';
    var surveysUrl = 'surveys/id/';
    if(pathname.indexOf(eventsUrl) >= 0 || pathname.indexOf(surveysUrl) >= 0){
      window.localStorage.setItem('returnUrl',pathname);
    }
  }

}
