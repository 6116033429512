import { Component, OnInit } from '@angular/core';
import { ParentsService } from 'src/app/shared/services/parents.service';
import { StudentsService } from 'src/app/shared/services/students.service';
import { ProfileService } from './../../../../shared/services/profile.service';
import { IStudentResume } from './../../../../shared/components/student-list-widget/models/IStudentResume';
import { ActivatedRoute, Router } from '@angular/router';
import { forEach } from 'lodash';
import {trigger,state,style,transition,animate} from '@angular/animations'
import { IBuspassDetails } from 'src/app/shared/components/student-list-widget/models/IBuspassDetails';
import { AppConfigService } from "src/app/services/app-config.service";
import { IStudentDetails } from 'src/app/shared/components/student-list-widget/models/IStudentDetails';
@Component({
  selector: 'app-buspass',
  templateUrl: './buspass.component.html',
  styleUrls: ['./buspass.component.scss'],
  animations: [
    trigger('flipstate', [
      state('active', style({
        transform: 'rotateY(180deg)' // Flip the card
      })),
      state('inactive', style({
        transform: 'rotateY(0)' // Original position
      })),
      transition('active => inactive', animate('600ms ease-out')),
      transition('inactive => active', animate('600ms ease-in'))
    ])
  ]
})
export class BuspassComponent implements OnInit {
flip='inactive';
  flipBusspass= false;


studentId = null;
students: IStudentResume = null;
  buspassData: IBuspassDetails[]=null;
  dispatchPhone:string;
  regString: string;
  regValue:string;
constructor(
  private profileService: ProfileService,
  private studentServices: StudentsService,
  private parentServices: ParentsService,
  private appConfigService: AppConfigService,
  private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    const currentUser = this.profileService.identity;
    this.dispatchPhone=this.appConfigService.configuration.environment.transportDispatch;
    this.fetchRegString();
    if(currentUser.userType.toLowerCase() == "student"){
      this.studentId = currentUser.userIdRole;
      this.getBusPassByStudentId();
      console.log(" This is the buss pass data",this.buspassData);


    }else if(currentUser.userType.toLowerCase() == "parent"){
      this.activeRoute.parent.params.subscribe((data) => {
        this.studentId = data.studentId;
        this.getBusPassByStudentId();
      console.log(" This is the buss pass data",this.buspassData);
      });
    }
    if(this.studentId != null){
      this.studentServices.getStudentById(this.studentId).subscribe((student:IStudentDetails) => {
        this.students = student;
        if (this.buspassData) {
          this.buspassData = this.buspassData;
          
          this.updateStudentData();  // Call update here to ensure data is available
        } else {
          this.buspassData = [];
          console.warn('No bus pass data received');
        }
       
      });
    }   
  }



  updateStudentData(){
    
    for (let index = 0; index < this.buspassData.length; index++) {
      let studentBuspass = this.buspassData[index];
      studentBuspass.name = this.students.firstName +" " + this.students.lastName;
      studentBuspass.school = this.students.schoolDescr;
      studentBuspass.studentId = this.students.studentId;     
     
    }
    console.log("after dispatch data"+this.buspassData);
  }
  getBusPassByStudentId(): void {
    this.studentServices.getBusPassByStudentId(this.studentId).subscribe((buspassData: IBuspassDetails[]) => {
      console.log("buspass data : " + buspassData)
      this.buspassData = buspassData;
     
    });
  }
  
  fetchRegString(): void {
    this.parentServices.getFeatureConfiguration().subscribe(
      (response: { name: string; value: string }[]) => {
        if (Array.isArray(response)) {
          const value = response.filter(x => x.name === 'transporation_program')[0].value;          
          this.regString = value;
          console.log("REG Value: ",   this.regString);
        } else {
          console.error('Unexpected response format:', response);
        }
      },
      (error) => {
        console.error('Error fetching REG Values:', error);
      }
    );
    console.log("REG Value: ",   this.regString);
  }  

  
}
