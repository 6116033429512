import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { StudentsService } from "src/app/shared/services/students.service";
import { ParentsService } from "src/app/shared/services/parents.service";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-parent-survey-list',
  templateUrl: './parent-survey-list.component.html',
  styleUrls: ['./parent-survey-list.component.scss']
})
export class ParentSurveyListComponent implements OnInit {
studentParent: any;
message: any ='';

surveyList = [];
  ngOnInit(): void {
    this.getsurveydata();
  }

  constructor(    private router: Router,		
    private activeRoute: ActivatedRoute,
		private studentService: StudentsService,
		private parentService: ParentsService,
		private toastr: ToastrService,){}

  takesurvey(survey){
    this.parentService.getStudentsByParent().subscribe(
      (response) => {
        //debugger
        this.studentParent = response.filter(x=> x.enrollmentStatus === 'Y' && x.verified === 'Y');
        if (this.studentParent.length > 0) {
          this.router.navigate(["parent/surveys/id/"+ survey.surveyId]);
				} else {
					this.message = 'Survey is only available to parents with enrolled students. Please add a student to your account and try again.';
				}
      }
    )
  }

  getsurveydata(){
		this.parentService.getParentSurveysList().subscribe(
			(response) => {
				if(response){
          this.surveyList = response;
          this.showhidesurveys();
        }
			  
			},
			(error) => {
			  console.error('Error fetching survey list:', error);
			},
			() => {
			 
		  }
		  );   
	}

  issurveynotactive(survey):boolean{
    if(survey && survey.startTime){
      let currDate = new Date();
      if(new Date(survey.startTime) < currDate && new Date(survey.endTime) > currDate){
        return false;
      }else{
        return true;
      }
    }
  }

  showhidesurveys(){
    for (let index = 0; index < this.surveyList.length; index++) {
      const survey = this.surveyList[index];
      let currDate:any = new Date();
      let endDate:any  = new Date(survey.endTime);
      let diffMs:number = currDate - endDate;
      if(diffMs > 0 && (diffMs/86400000) > 30){
         survey.show = false;
      }else {
        survey.show = true;
      }
    }

  }

}
