import { RouteDiscoverComponent } from "./route-discover.component";
import { Routes } from "@angular/router";
import { LayoutComponent } from "../layout/layout.component";
import { AuthGuard } from "../shared/auth/auth.guard";
import { StudentTestingDownloadComponent } from "../shared/components/students-timeline/student-testing-download/student-testing-download.component";

export const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: RouteDiscoverComponent,
      },
      {
        path: "parent",
        loadChildren: () =>
          import("./parent/parent.module").then((m) => m.ParentModule),
      },
      {
        path: "student",
        loadChildren: () =>
          import("./student/student.module").then((m) => m.StudentModule),
      },
      {
        path: "teacher",
        loadChildren: () =>
          import("./teacher/teacher.module").then((m) => m.TeacherModule),
      },
      {
        path: "counselor",
        loadChildren: () =>
          import("./counselor/counselor.module").then((m) => m.CounselorModule),
      },
      {
        path: "administrator",
        loadChildren: () =>
          import("./administrator/administrator.module").then(
            (m) => m.AdministratorModule
          ),
      }
    ],
  },
  {
    path: "events/id/:eventId",
    component: RouteDiscoverComponent
  },  {
    path: "surveys/id/:surveyId",
    component: RouteDiscoverComponent
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
path:"testingdownload",
component:StudentTestingDownloadComponent
  },

  // Not found
  { path: "**", redirectTo: "home" },
];
