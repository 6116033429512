import { forkJoin, Subscription } from "rxjs";
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { forEach } from 'lodash';
import { ActivatedRoute, Router } from "@angular/router";
import { StudentsService } from "src/app/shared/services/students.service";
import { ParentsService } from "src/app/shared/services/parents.service";
import { ToastrService } from 'ngx-toastr';
// import Target from "ol/events/Target";

@Component({
	selector: 'app-parent-survey',
	templateUrl: './parent-survey.component.html',
	styleUrls: ['./parent-survey.component.scss']
})
export class ParentSurveyComponent implements OnInit {
	finalSurvey: any[];
	isSubmitted = false;
	surveyData;
	surveyId;
	studentlist: any[];
	studentParent: any[];
	message: any = '';
	constructor(
		private router: Router,		
		private activeRoute: ActivatedRoute,
		private studentService: StudentsService,
		private parentService: ParentsService,
		private toastr: ToastrService,
	  ) {}

	ngOnInit(): void {
		this.parentService.getStudentsByParent().subscribe(
			(response) => {
			  //debugger
			  this.studentParent = response.filter(x=> x.enrollmentStatus === 'Y' && x.verified === 'Y');
			  if (this.studentParent.length > 0) {
				this.activeRoute.params.subscribe((data) => {
					if (data.id) {
						this.surveyId = data.id;	
						this.getsurveydata(data.id);
					}
				  })
					  } else {
						  this.message = 'Survey is only available to parents with enrolled students. Please add a student to your account and try again.';
					  }
			})
		//this.finalSurvey = this.rearrangeArray();
	}

	getsurveydata(surveyId){
		this.parentService.getParentSurvey(surveyId).subscribe(
			(response) => {
				if(response)
				this.finalSurvey = this.rearrangeArray(response);
			  console.log(response);
			},
			(error) => {
			  console.error('Error fetching survey data:', error);
			},
			() => {
			 
		  }
		  );   
	}
	rearrangeArray(response) {
		const nestedSurveys = response.reduce((surveys: any[], record: any) => {
			// Find if the survey already exists
			let survey = surveys.find(s => s.surveyId === record.surveyId);
			
			if (!survey) {
				// If the survey does not exist, create it
				survey = {
					surveyId: record.surveyId,
					surveyName: record.surveyName,
					schoolYear: record.schoolYear,
					startTime: record.startTime,
					endTime: record.endTime,
					surveyDescr: record.surveyDescr,
					surveyType: record.surveyType,
					header1: record.header1,
					header2: record.header2,
					header3: record.header3,
					header4: record.header4,
					questions: []
				};
				surveys.push(survey);
			}
	
			// Find if the question already exists within the survey
			let question = survey.questions.find(q => q.questionId === record.questionId);
			
			if (!question) {
				// If the question does not exist, create it
				question = {
					questionId: record.questionId,
					questionDescr: record.questionDescr,
					questionType: record.questionType,
					questionSort: record.questionSort,
					answers: []
				};
				survey.questions.push(question);
			}
	
			// Add the answer to the question
			question.answers.push({
				answerId: record.answerId,
				answerDescr: record.answerDescr,
				answerSort: record.answerSort
			});
	
			return surveys;
		}, []);
		
		return nestedSurveys;
	}
	
	changeresponse(control, question, answer) {
		let controlTarget = control.target;
		if(controlTarget.type == "textarea")
		{
			let selectedAnswerId = Number(answer.answerId);
			delete question.selectedResponse;
			question.selectedResponse = [];			
			question.selectedResponse.push(selectedAnswerId);
		}
		else
		{
			let selection = control.target.checked;
			let selectedAnswerId = Number(answer.answerId);
			if (question.questionType == "multiple") {
				if (question.selectedResponse) {
					let resIndex = question.selectedResponse.findIndex(res => res == selectedAnswerId)
					if (resIndex != -1) {
						if (!selection) {
							question.selectedResponse.splice(resIndex, 1);
						}
					} else {
						if (selection) {
							question.selectedResponse.push(selectedAnswerId);
						}
					}
				} else {
					question.selectedResponse = [];
					if (selection) {
						question.selectedResponse.push(selectedAnswerId);
					}
				}
			} else {
				delete question.selectedResponse;
				question.selectedResponse = [];
				if (selection)
					question.selectedResponse.push(selectedAnswerId);
			}
		}
	}

	submitsurvey(){
	

		let allquestionAnswered = true;
		let surveyResponses:CmdAddParentSurveyResponses = new CmdAddParentSurveyResponses();
		surveyResponses.SurveyType = this.finalSurvey[0].surveyType;
		for (let index = 0; index < this.finalSurvey[0].questions.length; index++) {
			const question = this.finalSurvey[0].questions[index];
				let questionResponse:SurveyQuestionResponse = new SurveyQuestionResponse();
				questionResponse.QuestionId = question.questionId.toString();
				questionResponse.SurveyId = Number(this.finalSurvey[0].surveyId);
				//questionResponse.StudentId = '1231212';
				questionResponse.ParentId = this.parentService.parentId;
				if(question.questionType == "text"){
					questionResponse.AnswerText = question.AnswerText;
					surveyResponses.Responses.push(questionResponse);	
									
				}else{
					if(question.selectedResponse && question.selectedResponse.length > 0){
						questionResponse.AnswerId = question.selectedResponse.sort((a, b) => a - b).toString();

				
						surveyResponses.Responses.push(questionResponse);
					}
					else{
						allquestionAnswered = false;
					}
				}

		}
		if(allquestionAnswered){
			this.parentService.addParentSurveyResponses(this.surveyId , surveyResponses).subscribe(
				(response) => {
				  console.log('Data saved successfully:', response);
				  if(!this.isSubmitted){
					this.toastr.success(`Your responses has been submitted`);
					this.isSubmitted = true;
				  }
					
				  setTimeout(() => {
					this.router.navigate(["parent/surveys"]);
				  }, 2000);
				},
				(error) => {
				  console.error('Error saving data:', error);
				},
				() => {
					this.isSubmitted = true;
			  }
			  );   
		}else{
			this.toastr.error(`Please respond to all the questions`);
		}
	}
}

export class CmdAddParentSurveyResponses {
    public Responses: SurveyQuestionResponse[];
	public CommandId:string;
	public SurveyType:string;
    constructor() {
        this.Responses = [];
		this.CommandId = "";
		this.SurveyType = "parent"
    }
}

export class SurveyQuestionResponse {
    public SurveyId: number;
    public ParentId: string;
    public StudentId: string;
    public QuestionId: string;
    public AnswerId: string;
    public AnswerText: string;
    public ResponseTime: Date;

    constructor() {
        this.SurveyId = 0;
        this.ParentId = "";
        this.StudentId = "";
        this.QuestionId = "";
        this.AnswerId = "";
        this.AnswerText = "";
        this.ResponseTime = new Date();
    }
}
