import { Component, OnInit, ViewChild } from '@angular/core';
import { StudentsService } from 'src/app/shared/services/students.service';
import { ParentsService } from 'src/app/shared/services/parents.service';
import { IStudentResume } from '../../student-list-widget/models/IStudentResume';
import { IStudentHealthConsent } from '../../student-list-widget/models/IStudentHealthConsent';
import { CommonModule } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms'; 
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';
import { MatRadioModule } from '@angular/material/radio';

@Component({
  selector: 'app-student-health-consent',
  templateUrl: './student-health-consent.component.html',
  styleUrls: ['./student-health-consent.component.scss']
})

export class StudentHealthConsentComponent implements OnInit {

  @ViewChild('consentForm') consentForm: NgForm;

    studentsHealthConsent: IStudentHealthConsent[] = [];
    students: IStudentResume[] = [];
    studentHealthConsentRadioGroups : any = [];
    parentName: string = "";
    parentPhonenumber:string = "";
    currentDate: string = (new Date()).toISOString().substring(0,10);
    phoneNumberInvalid: boolean = false;

  constructor( private parentService : ParentsService,
    private toastr: ToastrService, private studentService: StudentsService) { }

  ngOnInit(): void {
      this.parentService.getStudentHealthConsentByParentId().subscribe(
      (students) => {        
        this.bindToRadioGroups(students);
      },
      (error) => {      
        this.toastr.error(`Can't load Students - error Occured - ${error}`);
      }
    );    
  }

  bindToRadioGroups(studs: IStudentHealthConsent[]){
    console.log(studs);
    let radioBtnGroups : any = [];
    this.studentsHealthConsent = studs;       
    this.studentsHealthConsent.forEach((student) => {          
      let studentHealthConsentRadioGrp = {
        name : `${student.studentFirstName} ${student.studentLastName}`, 
        options:[
          {id : "Y", name: "Yes", "canChecked" : student.consent === "Y" ? true : false },
          {id: "N", name:"No", "canChecked" : student.consent === "N" ? true : false}
         ],
        selected:student.consent,        
        consentDate: this.formatDateTime(student.signatureDate)
      };  
     radioBtnGroups.push(studentHealthConsentRadioGrp);
    });
   this.getAllStudentsByParentToCompareWithSubmittedConsents(radioBtnGroups);
  }

  getAllStudentsByParentToCompareWithSubmittedConsents(submittedConsentsRadioGrps: any){
      // get all Students under this parent
      this.parentService.getStudentsByParent().subscribe(
        (students) => {
          let filteredStudents = students.filter(x=> x.enrollmentStatus == "Y");
          this.CheckforAllStudentConsentSubmit(filteredStudents,submittedConsentsRadioGrps);
        },
        (error) => {       
          this.toastr.error(`Can't load Students - ${error}`);
        }
      );
  }

  CheckforAllStudentConsentSubmit(studs : IStudentResume[], submittedConsentsRadioGrps: any){
  this.students = studs;
  console.log(this.students);     
  console.log(this.studentsHealthConsent);
 if(this.students.length !== submittedConsentsRadioGrps.length){
    this.students.forEach(stud => {
      let studentHealthCnst = this.studentsHealthConsent.filter(x=> x.studentId === stud.studentId);
      if(studentHealthCnst.length < 1){
        let studentHealthConsentRadioGrp = {
          name : `${stud.firstName} ${stud.lastName}`, 
          options:[
            {id : "Y", name: "Yes", "canChecked":false},
            {id: "N", name:"No", "canChecked":false}
           ],
          selected:'',
          consentDate:''
        };
        submittedConsentsRadioGrps.push(studentHealthConsentRadioGrp);
      }
    });    
  }

 
  submittedConsentsRadioGrps.sort((a,b) => {
    if(a.name < b.name){
      return -1;
    }
    if(a.name > b.name){
        return 1;
      }
      return 0;
  }); 
  this.studentHealthConsentRadioGroups = submittedConsentsRadioGrps;
}


  onChange(studentName: string, option: any){
    const grp = this.studentHealthConsentRadioGroups.find(g=>g.name === studentName);
    if(grp){
      grp.selected = option.value;
      console.log(studentName + option.value);
      let currentStudIndx = this.studentsHealthConsent.findIndex(x=>`${x.studentFirstName} ${x.studentLastName}` === studentName);
      if(currentStudIndx !== -1){
        let studobj = this.studentsHealthConsent[currentStudIndx];
        studobj.consent = option.value;
        this.studentsHealthConsent[currentStudIndx] = studobj;        
      }
      else{
        let student = this.students.find(x=>studentName.includes(x.firstName));
        this.studentsHealthConsent.push(this.addstudentHealthConsentRecord(student, option.value))
      }
    }
    console.log(this.studentsHealthConsent);
  }


  onSubmit(){
/*
  this.phoneNumberInvalid = this.parentPhonenumber === '';
  this.phoneNumberInvalid = this.parentPhonenumber.length != 10;
  if(this.phoneNumberInvalid ){
    return;
  }
*/
  this.studentsHealthConsent.forEach(stud => {
    stud.signature = this.parentName;
    stud.phoneNumber = this.parentPhonenumber;
  });
  this.parentService
  .saveStudentHealthConsent(this.studentsHealthConsent)
  .subscribe(
    (response) => {
      this.toastr.success('Student health consent saved successfully!', 'Success');
      console.log(response); // Handle success response
      setTimeout(() => {
        window.location.reload();
      }, 2000); 
     
    },
    (error) => {
      this.toastr.error('Failed to save student health consent.', 'Error');
      console.error(error); // Handle error response
    }
  );  
 }

 addstudentHealthConsentRecord(student: IStudentResume, consent: string) : IStudentHealthConsent{
  let studHealthConsent : IStudentHealthConsent = {  
    studentHealthConsentId: 0,
    schoolyear: '0',
    parentId: this.parentService.parentId,  
    studentId: student.studentId,
    studentFirstName: student.firstName,
    studentLastName: student.lastName,
    costCenterCode: student.costCenterCode,
    consent: consent,
    signatureDate:  new Date(),
    signature: this.parentName,
    phoneNumber:this.parentPhonenumber,
    isNotificationSent: false
  };
  return studHealthConsent;
 }


 formatDateTime(isoString: any): string { 
  const date = new Date(isoString.toString()); 
  const year = date.getFullYear(); 
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
  const day = date.getDate().toString().padStart(2, '0'); 
  const hours = date.getHours().toString().padStart(2, '0'); 
  const minutes = date.getMinutes().toString().padStart(2, '0'); 
  const seconds = date.getSeconds().toString().padStart(2, '0'); 
  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; 
  return formattedDateTime;
 }

}