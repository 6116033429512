import { forkJoin, Subscription } from "rxjs";
import { BusService } from "./../../../../shared/services/bus.service";
import { IFaqGroup } from "./../../../../shared/components/faq-section-widget/models/Faq";
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { StudentsService } from "src/app/shared/services/students.service";
import { ParentsService } from "src/app/shared/services/parents.service";
import { HelpCenterService } from "src/app/shared/services/help-center.service";
import { GeocodeService } from "../../../services/geocode.service";
import { TourService } from "ngx-ui-tour-md-menu";
import { MdMenuPlacement } from "ngx-ui-tour-md-menu";
import {
  Profile,
  ProfileService,
} from "src/app/shared/services/profile.service";
import swal from "sweetalert";
import { NotificationServices } from "src/app/shared/services/notification.service";
import { ignoreElements } from "rxjs/operators";
import * as _ from "lodash";
import * as m from "moment";
import { async } from "rxjs/internal/scheduler/async";
import { CalendarService } from "src/app/shared/services/calendar.service";
import { FeedServiceService } from "src/app/shared/services/feed-service.service";
import * as mtz from 'moment-timezone';
import { FormControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { StudentTransportRequest } from "./models/Student-TransportRequest";
import { MatTabChangeEvent } from "@angular/material/tabs";

// import {} from '@types/googlemaps';
declare var google;

declare var ol: any;
@Component({
  selector: "app-student-bus",
  templateUrl: "./student-bus.component.html",
  styleUrls: ["./student-bus.component.scss"],
})
export class StudentBusComponent implements OnInit {
  zoom: number = 10;
  // initial center position for the map
  lat: number = 33.9391064;
  lng: number = 118.0535701;
  segment = "pickup";
  isMapEnabled: boolean = false;
  routeTracking: Subscription = null;
  stopLocation: any = null;
  tracking: any = null;
  time: any = null;
  isAfterHours: boolean = null;
  studentId = null;
  pickup = null;
  dropoff = null;
  intervalTracking = null;
  markers: any[] = [null, null, null];
  bus: any = null;
  faq: IFaqGroup[] = null;
  lastUpdated: any;
  id;
  schoolFailed = false;
  pickupEnabled = false;
  dropoffEnabled = false;
  fitBounds: boolean = true;
  sub: Subscription;
  initial = 0;
  busRoute = ""
  selectedTabIndex = 0;
  selectedMainTabIndex = 0;
  isHoliday: boolean = false;
  handleTabs: boolean = false;
  tabLabelNext: String = '';
  tabLabelCurrent: String = '';

  displayAcknowlegement: boolean = false;
  currentRequestSubmitted: boolean = false;
  nextRequestSubmitted: boolean = false;
  showBusRequestForm: boolean = false;
  showBusRequestFormNextYear: boolean = false;
  requestSubmissionDate: String; 
  requestDetails: any;
  currentRider: boolean = false;
  currentSchoolYear: String = ''; 
  nextSchoolYear: String = '';
  submitCounter: number = 0;

  transportationGuranteeAck: boolean = false;
  transportationGuidelinesAck: boolean = false;
  specialTransportationNeedsReq: boolean = false;
  consentLegalAuthorityAck: boolean = false;

  parentName = "";
  formattedDate: Date = new Date();
  submissionDate: Date | string;
  currentYearRequestSubmissionDate: Date | String;
  nextYearRequestSubmissionDate: Date | String;
  showCurrentYearTransportOptOut : boolean = false;
  showNextYearTransportOptOut : boolean = false;
  showTransportOptIn : boolean = false;
  optOutTransport : boolean = false;
  busRequestForm: FormGroup;
  currentYearActiveTabStatus=true;
  nextYearActiveTabStatus=true;
  currentYearEnableMonth:string;
  currentYearEnable:string;
  nextYearEnable:string;
  nextYearEnableMonth:string;
  optOutConsentLegalAuthorityAck : boolean = false;
  busOptOutRequestForm : FormGroup;
  optOutParentName : string;
  optOutSubmissionDate : Date | string;
  currentMatTabIndexOptOut : Number = 0;
  currentYearRequestStatus : string;
  nextYearRequestStatus: string;
  pendingStatusPeriodDays : number = 7;
  getRequestSchoolYear : string;
  isOptOutFormSubmitted = false;

  options = [
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ] as google.maps.MapTypeStyle[];

  googleMaps: any;
  @ViewChild("map") mapElementRef: ElementRef;
  busMarker;

  constructor(
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
    private parentService: ParentsService,
    private helpService: HelpCenterService,
    private busService: BusService,
    private geocodeService: GeocodeService,
    public tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    private renderer: Renderer2,
    private feedsService: FeedServiceService,
    private calendarService: CalendarService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
  ) {}

  get isParent() {
    return this.profileService.profile.role == 'Parent'
  }

  get isStudent() {
    return this.profileService.profile.role == 'Student'
  }

  ngOnInit(): void {
    this.studentId = this.activeRoute.snapshot.params.studentId;
    this.checkHoliday();
    this.getSchoolYear();
    this.getRequestSchoolYear = this.currentSchoolYear.replace(/-/g,'');
    this.checkStudentBusRequestStatus(this.studentId);
    this.checkTransportStatusDetails();

    //Validation for the Bus Request form 
    this.submissionDate = this.formattedDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    this.optOutSubmissionDate = this.submissionDate;
    this.busRequestForm = this.formBuilder.group({
      parentName: ['', Validators.required],
      submissionDate: [this.submissionDate, Validators.required],
      transportationGuranteeAck: [false, Validators.required],
      transportationGuidelinesAck: [false, Validators.required],
      consentLegalAuthorityAck: [false, Validators.required],
      specialTransportationNeedsReq: [false],
    });

    this.busOptOutRequestForm = this.formBuilder.group({
      optOutSubmissionDate: [this.optOutSubmissionDate, Validators.required],
      optOutParentName :['', Validators.required],
      optOutConsentLegalAuthorityAck :  [false, Validators.required],
      optOutTransport:  [false, Validators.required]
    });

   
    this.tabLabelCurrent = 'Request Transportation Fall ' + this.currentSchoolYear;
    this.tabLabelNext = 'Request Transportation Fall ' + this.nextSchoolYear;
  }

  onTabChange() {
    this.handleTabs = !this.handleTabs
    sessionStorage.setItem("handleTabs", JSON.stringify(this.handleTabs))
  }

  ngAfterViewInit() {
    this.getGoogleMaps()
      .then((googleMaps) => {
        this.googleMaps = googleMaps;
        this.createMarkers(this.markers);
        //this.enableMap();
        this.helpService.getFaq("Transportation").subscribe((faq) => {
          this.faq = faq;
        });

      })
      .catch((err) => {
        console.log(err);
        console.log("retrying");
      });

  }
  loadData(event) {
    this.createMarkers(this.markers);
    this.helpService.getFaq("Transportation").subscribe((faq) => {
      this.faq = faq;
    });
    if (event) {
      event.target.complete();
    }
  }

  createMarkers(markers) {
    this.id = this.studentId;
    let schoolInfo$ = this.studentService
      .getStudentSchoolInfo(this.studentId)

    let busRoutes$ = this.studentService
      .getStudentBusRoutes(this.studentId)

      let busDelays$ = this.studentService
      .getStudentBusRouteDelays(this.studentId)


      forkJoin({
        schoolInfo: schoolInfo$,
        busRoutes: busRoutes$,
        busDelays: busDelays$
      }).subscribe((response)=>{
        this.processSchoolInfo(response.schoolInfo, markers);
        console.log(response.busRoutes, 'bus===')
        this.processBusRoutes(response.busRoutes, markers);
        this.processBusDelays(response.busDelays)
        this.enableMap();
      })
    this.fitBounds = false;
  }
  processSchoolInfo(schoolInfo, markers){
    let schollName = schoolInfo.schoolName;
    this.geocodeService
      .geocodeAddress(`${schoolInfo.streetAddress}, Los Angeles County, CA, USA`)
      .subscribe((location) => {
        if (location) {
          this.schoolFailed = false;
          this.stopLocation = schollName;
          this.lat = location["lat"];
          this.lng = location["lng"];
          markers[0] = {
            lat: Number(location["lat"]),
            lng: Number(location["lng"]),
            icon: "/assets/img/school_map_marker.png",
            label: schollName,
          };
        } else {
          this.schoolFailed = true;
        }
        this.jsMap(markers)
      });
  }
  processBusRoutes(response, markers){
    const hour = mtz().tz("America/Los_Angeles").hour();
    const routeIndex = hour > 12 ? 1: 0;
    console.log(routeIndex)
    this.bus = response;
    if (response.routes?.length > 0) {
      this.showCurrentYearTransportOptOut = true;
      this.pickupEnabled = response.routes[0]
      this.dropoffEnabled = response.routes[1]
      this.pickup = _.find(response.routes, { 'amPm': 'AM'});
      this.dropoff = _.find(response.routes, { 'amPm': 'PM'});;
      this.bus.routes = _.orderBy(this.bus.routes, ['amPm'], ['asc']);

      this.selectedTabIndex = hour > 12 ? 1 : 0;
      let address = this.pickup?.stopLocation || this.dropoff?.stopLocation;
      if (address) {

        if (this.checkTime() && !this.isHoliday) {

          //If both are available, allow for route toggle
          if(this.pickup && this.dropoff){
            this.studentService
            .getStudentTracking(
              this.studentId,
              routeIndex == 0 ? this.pickup.routeNumber : this.dropoff.routeNumber
            )
            .subscribe((tracking) => {
              if (tracking.length > 0) {
                this.studentId = this.studentId;
                this.tracking = tracking;
                markers[2] = {
                  lat: Number(tracking[0]?.latitude),
                  lng: Number(tracking[0]?.longitude),
                  icon: "/assets/img/bus_map_marker.png",
                  label: "Bus Location",
                };
              } else {
                this.busRoute = "No bus location available at this moment."
              }
            });  
            
            // this.studentService
            // .getStudentTracking(
            //   this.studentId,
            //   routeIndex == 0 ? this.pickup.routeNumber : this.dropoff.routeNumber
            // )
            // .subscribe((tracking) => {
            //   if (tracking.length > 0) {
            //     this.studentId = this.studentId;
            //     this.tracking = tracking;
            //     markers[2] = {
            //       lat: Number(tracking[0]?.latitude),
            //       lng: Number(tracking[0]?.longitude),
            //       icon: "/assets/img/bus_map_marker.png",
            //       label: "Bus Location",
            //     };
            //   } else {
            //     this.busRoute = "No bus location available at this moment."
            //   }
            // });
          } else {

            //Otherwise just use one route to be displayed
            const routeNumber = this.pickup?.routeNumber || this.dropoff?.routeNumber;
            console.log(routeNumber)
            this.studentService
            .getStudentTracking(
              this.studentId,
              routeNumber
            )
            .subscribe((tracking) => {
              if (tracking.length > 0) {
                this.studentId = this.studentId;
                this.tracking = tracking;
                markers[2] = {
                  lat: Number(tracking[0]?.latitude),
                  lng: Number(tracking[0]?.longitude),
                  icon: "/assets/img/bus_map_marker.png",
                  label: "Bus Location",
                };
              } else {
                this.busRoute = "No bus location available at this moment."
              }
            });   
            
            // this.studentService
            // .getStudentTracking(
            //   this.studentId,
            //   routeNumber
            // )
            // .subscribe((tracking) => {
            //   if (tracking.length > 0) {
            //     this.studentId = this.studentId;
            //     this.tracking = tracking;
            //     markers[2] = {
            //       lat: Number(tracking[0]?.latitude),
            //       lng: Number(tracking[0]?.longitude),
            //       icon: "/assets/img/bus_map_marker.png",
            //       label: "Bus Location",
            //     };
            //   } else {
            //     this.busRoute = "No bus location available at this moment."
            //   }
            // });
          }
       
        }


        if(response.routes[routeIndex]){
          this.geocodeService
          .geocodeAddress(`${response.routes[routeIndex].stopLocation}, Los Angeles County, CA, USA`)
          .subscribe((location) => {
            if (location) {
              this.stopLocation = address;
              this.lat = location["lat"];
              this.lng = location["lng"];
              markers[1] = {
                lat: Number(location["lat"]),
                lng: Number(location["lng"]),
                icon: "/assets/img/stop_map_marker.png",
                label: response.routes[routeIndex].stopLocation,
              };
              this.jsMap(markers);
            }
          });
        } else {
          this.geocodeService
          .geocodeAddress(`${response.routes[0].stopLocation}, Los Angeles County, CA, USA`)
          .subscribe((location) => {
            if (location) {
              this.stopLocation = address;
              this.lat = location["lat"];
              this.lng = location["lng"];
              markers[1] = {
                lat: Number(location["lat"]),
                lng: Number(location["lng"]),
                icon: "/assets/img/stop_map_marker.png",
                label: response.routes[0].stopLocation,
              };
              this.jsMap(markers);
            }
          });
        }

       
      }
      this.currentRequestSubmitted = true;
      this.currentRider = true;
    }
  }

  checkHoliday() {
    const schoolCode = this.studentService.currentStudent().schoolCode;
    const today = m().startOf('day').toISOString();
    const date = m().format('YYYY-M-D');

    this.calendarService.getSchoolEventsByDate(date, date, schoolCode).subscribe( res => {

      if(res.length > 0) {
        _.each(res, event => {
          event.startDay = m(event.calendarDay).startOf('day').toISOString();
        })
        if(_.find(res, { 'startDay': today })) {
          this.isHoliday = true; //If holiday
        } else this.isHoliday = false
      } else this.isHoliday = false
    })

  }

  processBusDelays(response){

    response.routes = _.orderBy(response.routes, ['amPm'], ['asc'])


    const hour = new Date().getHours();
    if (response.routes?.length > 0) {
      this.pickupEnabled = response.routes[0];
      this.dropoffEnabled = response.routes[1];
      this.bus.routes = _.orderBy(this.bus.routes, ['amPm'], ['asc']);
      if(this.bus.routes[0]){
        this.bus.routes[0].delay = response.routes[0].delay;
        this.bus.routes[0].incidentTime = response.routes[0].incidentTime;
        this.bus.routes[0].type = response.routes[0].type;
      }
      if(this.bus.routes[1]){
        this.bus.routes[1].delay = response.routes[1].delay;
        this.bus.routes[1].incidentTime = response.routes[1].incidentTime;
        this.bus.routes[1].type = response.routes[1].type;
      }
    }
  }

  jsMap(markers) {
    const mapEl = this.mapElementRef.nativeElement;
    markers = markers.filter(m=>m!=null);
    const marker = markers.find(m=>m.lat)
    const map = new this.googleMaps.Map(mapEl, {
      center: { lat: marker.lat, lng: marker.lng },
      zoom: 10,
      mapTypeControlOptions: {
        mapTypeIds: ["mystyle"],
      },
      mapTypeId: "mystyle",
      mapTypeControl: false,
    });
    this.googleMaps.event.addListenerOnce(map, "idle", () => {
      this.renderer.addClass(mapEl, "visible");
    });

    map.mapTypes.set(
      "mystyle",
      new google.maps.StyledMapType(this.options, { name: "My Style" })
    );

    markers.forEach((mark) => {
      if (mark && mark !== null) {
        const marker = new this.googleMaps.Marker({
          position: { lat: mark.lat, lng: mark.lng },
          // eslint-disable-next-line object-shorthand
          map: map,
          title: mark.label,
          icon: mark.icon,
          draggable: false,
        });
        if (mark.label === "Bus Location") {
          this.busMarker = marker;
        }
        marker.setMap(map);
      }
    });
  }

  enableMap() {
    if (this.isMapEnabled) return;
    this.isMapEnabled = true;
    let self = this;
    this.intervalTracking = setInterval(() => {
      let currentTime = new Date().getHours();
      let track
      if(self.dropoff && self.pickup){
        track = this.selectedTabIndex === 1 ? self.dropoff: self.pickup;
      } else if(self.dropoff){
        track = self.dropoff
      } else {
        track = self.pickup
      }

      if (this.checkTime() && !this.isHoliday) {
        this.studentService
          .getStudentTracking(self.studentId, track.routeNumber)
          .subscribe((tracking) => {
              if(JSON.parse(sessionStorage.getItem("handleTabs")) === false) {
                sessionStorage.setItem("handleTabs", JSON.stringify(null))
                this.markers[2] = {
                  lat: Number(tracking[0]?.latitude),
                  lng: Number(tracking[0]?.longitude),
                  icon: "",
                  label: "Bus Locationss",
                };
                this.busRoute = "No bus location available at this moment."
                this.jsMap(this.markers);
              }
            if(tracking.length == 0) return;
            this.tracking = tracking;
            if (this.busMarker && this.tracking && JSON.parse(sessionStorage.getItem("handleTabs")) == null) {
              const latLng = new google.maps.LatLng(
                Number(tracking[0].latitude),
                Number(tracking[0].longitude)
              );
              this.busMarker.setPosition(latLng);
            } 
            else {
              sessionStorage.setItem("handleTabs", JSON.stringify(null))
              this.markers[2] = {
                lat: Number(tracking[0].latitude),
                lng: Number(tracking[0].longitude),
                icon: "/assets/img/bus_map_marker.png",
                label: "Bus Location",
              };
              this.busRoute = ""
              this.jsMap(this.markers);
            }
          });
      }

    
    }, 10000);
  }

  phoneNumber(value: string): string {
    var cleaned = ("" + value).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  checkTime(): boolean {
    const hour = new Date().getHours();
    if (hour >= 6 && hour <= 18) {
      return true;
    } else {
      return false;
    }
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: "above" };
    let below: MdMenuPlacement = { yPosition: "below" };
    let left: MdMenuPlacement = { xPosition: "before" };
    let right: MdMenuPlacement = { xPosition: "after" };

    this.tourService.initialize([
      {
        anchorId: "start-tour",
        content: "Bus routes will be updated daily here",
        title: "Welcome to School Bus!",
        placement: above,
      },
    ]);
    if (this.profileService.profile.hasTour) {
      this.tourService.start();
    }
  }

  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: "warning",
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: "Yes, turn off tour",
          value: true,
          visible: true,
          closeModal: true,
        },
      },
    }).then((res) => {
      if (res) {
        // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  segmentChanged(ev: any) {
    this.segment = ev.detail.value;
  }

  private getGoogleMaps(): Promise<any> {
    const win = window as any;
    const googleModule = win.google;
    if (googleModule && googleModule.maps) {
      return Promise.resolve(googleModule.maps);
    }
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${localStorage.getItem(
        "app_gkey"
      )}`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = () => {
        const loadedGoogleModule = win.google;
        if (loadedGoogleModule && loadedGoogleModule.maps) {
          resolve(loadedGoogleModule.maps);
        } else {
          reject("Google maps SDK not available");
        }
      };
    });
  }
  ngOnDestroy() {
    if (this.intervalTracking) clearInterval(this.intervalTracking);
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  toggleRequestAcknowledgements() {
    if (this.displayAcknowlegement == true){
        this.displayAcknowlegement = false;
        this.cancelBusRequest();
    }
    else {
        this.displayAcknowlegement = true;
    }
  }  

  onRequestTabChange(event: MatTabChangeEvent) {
    if(event.tab.textLabel.indexOf(this.currentSchoolYear.toString()) > 0){
      this.getRequestSchoolYear = this.currentSchoolYear.replace(/-/g,'');
    }else{
      this.getRequestSchoolYear = this.nextSchoolYear.replace(/-/g,'');
    }
    this.currentMatTabIndexOptOut = event.index;
    this.cancelBusRequest();
  }  

  submittransportationRequest(){
    this.submitCounter = this.submitCounter + 1;
    if (this.busRequestForm.valid) {
           
      console.log("Transportation Request Validated");
      console.log('Form submitted:', this.busRequestForm.value);

      const busRequestData = {        
        parentName: this.busRequestForm.get('parentName').value,
        submissionDate: this.busRequestForm.get('submissionDate').value,
        specialNeedsRequest: this.busRequestForm.get('specialTransportationNeedsReq').value,
        studentId: this.studentId,
        currentSchoolYear: this.showBusRequestForm,
        nextSchoolYear: this.showBusRequestFormNextYear,
        isOptOut: false,
      };

      if(busRequestData.specialNeedsRequest == true)
        busRequestData.specialNeedsRequest = 'Y';
      else
        busRequestData.specialNeedsRequest = 'N';

      this.parentService.addTransportationRequest(busRequestData).subscribe(
        (response) => {
          console.log('Data saved successfully:', response);
          if(this.submitCounter == 1)
            this.toastr.success(`Transportation request created`);
          window.location.reload();
        },
        (error) => {
          console.error('Error saving data:', error);
        },
        () => {
          this.submitCounter = 1;
      }
      );           
    } else {
      console.log('INVALID: Form submitted:', this.busRequestForm.value);
    }
  }

  cancelBusRequest(){
    this.displayAcknowlegement = false;
    this.currentRequestSubmitted = false;
    this.nextRequestSubmitted = false;
    this.showBusRequestForm = false;
    this.showBusRequestFormNextYear = false;

    this.checkStudentBusRequestStatus(this.studentId);

    this.busRequestForm.reset();

    //Reset form values
    this.busRequestForm.patchValue({
      parentName: '',
      submissionDate: this.submissionDate,
      transportationGuranteeAck: false,
      transportationGuidelinesAck: false,
      specialTransportationNeedsReq: false,
      consentLegalAuthorityAck: false
    });
  }

  checkTransportStatusDetails(){
    this.parentService.getFeatureConfiguration().subscribe(
      (response: any) => {
        console.log(`Tranports resposne is ${response}`);
        this.currentYearActiveTabStatus = response?.filter(x=>x.name === 'transportation_current_year_active')[0]?.value == "Y" ? true : false;
        this.nextYearActiveTabStatus = response?.filter(x=>x.name === 'transportation_future_year_active')[0]?.value == "Y" ? true : false;  
        this.currentYearEnable= response?.filter(x=>x.name === 'trans_curr_year_enable_year')[0]?.value;
        this.currentYearEnableMonth=   response?.filter(x=>x.name === 'trans_curr_year_enable_month')[0]?.value; 
        this.nextYearEnable= response?.filter(x=>x.name === 'trans_future_year_enable_year')[0]?.value;
        this.nextYearEnableMonth=   response?.filter(x=>x.name === 'trans_future_year_enable_month')[0]?.value; 
      },
      (error) => {
        console.error('Error fetching bus request data:', error);
      }
    );
  }

  checkStudentBusRequestStatus(studentId){
    this.parentService.getStudentBusRequestStatus(studentId, this.getRequestSchoolYear).subscribe(
      (response: StudentTransportRequest[]) => {
        console.log('Student Bus Request Data:', response);
        this.requestDetails = response;  
        if(this.requestDetails.length > 0){
          for (const requestDetail of this.requestDetails) {
            let currentDate : any = new Date();
            let submittedDate : any = new Date(requestDetail.submissionDate);
            const submissionDate = new Date(requestDetail.submissionDate).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
            let submittedAgoInDays = ((currentDate - submittedDate) / (24 * 60 * 60 * 1000));
            console.log('Request submission date', submissionDate);
            if(this.isCurrentYear(requestDetail.schoolYear)){
              this.currentYearRequestSubmissionDate = submissionDate;
              this.currentRequestSubmitted = true;
              this.currentYearRequestStatus = requestDetail.requestStatus;
             if(submittedAgoInDays >= this.pendingStatusPeriodDays && this.currentYearRequestStatus == "OPT-IN"){
              this.showCurrentYearTransportOptOut = true;
             }else{
              this.showCurrentYearTransportOptOut = false;
             }
             if((submittedAgoInDays >= this.pendingStatusPeriodDays 
              && this.currentYearRequestStatus == "OPT-OUT")){
              this.currentRequestSubmitted = false;
             }
            }else{
              this.nextYearRequestSubmissionDate = submissionDate;
              this.nextRequestSubmitted = true;  
              this.nextYearRequestStatus = requestDetail.requestStatus;   
              this.showNextYearTransportOptOut = submittedAgoInDays >= this.pendingStatusPeriodDays;         
              this.showNextYearTransportOptOut = this.showNextYearTransportOptOut && (this.nextYearRequestStatus != "OPT-OUT");
              if(submittedAgoInDays >= this.pendingStatusPeriodDays && this.nextYearRequestStatus == "OPT-IN"){
                this.showNextYearTransportOptOut = true;
               }else{
                this.showNextYearTransportOptOut = false;
               }
               if((submittedAgoInDays >= this.pendingStatusPeriodDays 
                && this.nextYearRequestStatus == "OPT-OUT")){
                this.nextRequestSubmitted = false;
               }
            }  
          }
        }else{
          this.nextRequestSubmitted = false;
        }

        console.log("Current Year Request: " + this.currentRequestSubmitted);
        console.log("Next Year Request: " + this.nextRequestSubmitted);
        console.log("Show Bus Request Form: " + this.showBusRequestForm);
        console.log("Show Next Bus Request Form: " + this.showBusRequestFormNextYear);
      },
      (error) => {
        console.error('Error fetching bus request data:', error);
      }
    );
  }

  isCurrentYear(schoolYear) {
    const firstYear = parseInt(schoolYear.substring(0, 4));
    const lastYear = parseInt(schoolYear.substring(4, 8));
  
    if (!isNaN(firstYear) && !isNaN(lastYear)) {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
  
      if (currentMonth < 7) {
        if (currentYear == lastYear) {
          return true;
        } else {
          return false;
        }
      }else if (currentMonth >= 7) {
        if (currentYear == firstYear) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }

  getSchoolYear() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    if (currentMonth < 7) {
      this.currentSchoolYear = `${currentYear - 1}-${currentYear}`;
      this.nextSchoolYear = `${currentYear}-${currentYear + 1}`;
    } else {
      this.currentSchoolYear = `${currentYear}-${currentYear + 1}`;
      this.nextSchoolYear = `${currentYear + 1}-${currentYear + 2}`;
    }
  }

  cancelOptOutBusRequest() {
    this.optOutTransport = false;
    this.busOptOutRequestForm.patchValue({
      optOutSubmissionDate: this.optOutSubmissionDate,
      optOutParentName : "",
      optOutConsentLegalAuthorityAck : false,
      optOutTransport: false
    });
  }

  submitOptOutTransportationRequest() {
    this.isOptOutFormSubmitted = true;
    if (this.busOptOutRequestForm.valid) {  
      console.log("Transportation Request Validated");
      console.log('Form submitted:', this.busOptOutRequestForm.value);

      const busOptOutRequestData = {        
        parentName: this.busOptOutRequestForm.get('optOutParentName').value,
        submissionDate: this.busOptOutRequestForm.get('optOutSubmissionDate').value,
        specialNeedsRequest: 'N',
        studentId: this.studentId,
        currentSchoolYear: this.currentSchoolYear == this.getRequestSchoolYear.substring(0,4)+'-'+this.getRequestSchoolYear.substring(4,8),
        nextSchoolYear: this.nextSchoolYear == this.getRequestSchoolYear.substring(0,4)+'-'+this.getRequestSchoolYear.substring(4,8),
        isOptOut: true,
      };
      this.parentService.addTransportationRequest(busOptOutRequestData).subscribe(
        (response) => {
          console.log('Data saved successfully:', response);
          if(this.isOptOutFormSubmitted)
            this.toastr.success(`Transportation Opt-Out request created`);
          window.location.reload();
        },
        (error) => {
          console.error('Error saving data:', error);
        }
      );           
    } else {
      console.log('INVALID: Form submitted:', this.busOptOutRequestForm.value);
    }

  }
}
