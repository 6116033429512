import { Component } from '@angular/core';

@Component({
  selector: 'app-student-tutoring-services',
  templateUrl: './student-tutoring-services.component.html',
  styleUrls: ['./student-tutoring-services.component.scss']
})
export class StudentTutoringServicesComponent {

}

